import { EmbeddedLayout, LbLogoLoading, useModules, usePermissions } from "@lb/frontend";
import { drmClientUrls } from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";

const EmbeddedDocuments = lazy(() => import("./pages/Documents"));

const EmbeddedDocumentUploadEdit = lazy(() => import("./pages/Documents/UploadEdit"));

const EmbeddedEditDocument = lazy(() => import("./pages/Documents/Edit"));

const Documents = lazy(
  () => import("./pages/Documents")

  // {
  // return usePermissions.getState().permissions?.drm?.documents &&
  //   useModules.getState().modules?.drm?.active
  //   ? import("./pages/Documents")
  //   : import("./pages/AccessDenied");
  // }
);

const DocumentUploadEdit = lazy(
  () => import("./pages/Documents/UploadEdit")
  // usePermissions.getState().permissions?.drm?.documents?.create &&
  // useModules.getState().modules?.drm?.documents?.upload
  //   ? import("./pages/Documents/UploadEdit")
  //   : import("./pages/AccessDenied")
);

const EditDocument = lazy(
  () => import("./pages/Documents/Edit")
  // usePermissions.getState().permissions?.drm?.documents?.edit &&
  // useModules.getState().modules?.drm?.documents?.update
  //   ? import("./pages/Documents/Edit")
  //   : import("./pages/AccessDenied")
);

const Tags = lazy(
  () => import("./pages/Tags")
  // usePermissions.getState().permissions?.drm?.tags &&
  // useModules.getState().modules?.drm?.tags?.create
  //   ? import("./pages/Tags")
  //   : import("./pages/AccessDenied")
);

const AddTags = lazy(
  () => import("./pages/Tags/AddEdit")
  // usePermissions.getState().permissions?.drm?.tags?.create &&
  // useModules.getState().modules?.drm?.tags?.create
  //   ? import("./pages/Tags/AddEdit")
  //   : import("./pages/AccessDenied")
);

const EditTags = lazy(
  () => import("./pages/Tags/AddEdit")
  // usePermissions.getState().permissions?.drm?.tags?.update &&
  // useModules.getState().modules?.drm?.tags?.update
  //   ? import("./pages/Tags/AddEdit")
  //   : import("./pages/AccessDenied")
);
const AppLayout = lazy(() => import("./components/AppLayout"));

const router = createBrowserRouter([
  {
    path: "/embedded",
    element: <EmbeddedLayout />,
    children: [
      {
        path: drmClientUrls.documents.slug,
        element: <EmbeddedDocuments />,
      },
      {
        path: drmClientUrls.documents.slug + "/" + drmClientUrls.documents.upload.slug,
        element: <EmbeddedDocumentUploadEdit />,
      },
      {
        path: drmClientUrls.documents.slug + "/" + drmClientUrls.documents.update.slug,
        element: <EmbeddedEditDocument />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<LbLogoLoading />}>
        <AppLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={drmClientUrls.documents.slug} replace />,
      },
      {
        path: drmClientUrls.documents.slug,
        children: [
          {
            path: "",
            element: <Documents />,
            children: [
              {
                path: drmClientUrls.documents.upload.slug,
                element: <DocumentUploadEdit />,
              },
            ],
          },
          {
            path: drmClientUrls.documents.update.slug,
            element: <EditDocument />,
          },
        ],
      },
      {
        path: drmClientUrls.tags.slug,
        element: <Tags />,
        children: [
          {
            path: drmClientUrls.tags.create.slug,
            element: <AddTags />,
          },
          {
            path: drmClientUrls.tags.update.slug,
            element: <EditTags />,
          },
        ],
      },
    ],
  },
]);

export default router;
